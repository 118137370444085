var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "CHeader",
    { attrs: { "with-subheader": "" } },
    [
      _c("CToggler", {
        staticClass: "ml-3 d-lg-none",
        attrs: { "in-header": "" },
        on: {
          click: function($event) {
            return _vm.$store.commit("toggleSidebarMobile")
          }
        }
      }),
      _c("CToggler", {
        staticClass: "ml-3 d-md-down-none",
        attrs: { "in-header": "" },
        on: {
          click: function($event) {
            return _vm.$store.commit("toggleSidebarDesktop")
          }
        }
      }),
      _c("CHeaderBrand", {
        staticClass: "mx-auto d-lg-none",
        attrs: {
          src: "@/assets/images/logo.png",
          width: "190",
          height: "46",
          alt: "The Source Logo"
        }
      }),
      _c(
        "CHeaderNav",
        { staticClass: "d-sm-down-none mr-auto" },
        [
          _c(
            "CHeaderNavItem",
            { staticClass: "px-3" },
            [
              _c("CHeaderNavLink", { attrs: { to: "/" } }, [
                _vm._v(" Dashboard ")
              ])
            ],
            1
          ),
          _c(
            "CHeaderNavItem",
            { staticClass: "px-3" },
            [
              _c("CHeaderNavLink", { attrs: { to: "/support" } }, [
                _vm._v(" Known Issues / Support ")
              ])
            ],
            1
          ),
          _c(
            "CHeaderNavItem",
            { staticClass: "px-3" },
            [
              _c(
                "CHeaderNavLink",
                { attrs: { to: "/tasks" } },
                [
                  _c("div", { staticStyle: { "padding-right": "40px" } }, [
                    _vm._v("Tasks")
                  ]),
                  _vm.$root.taskCount > 0
                    ? _c(
                        "CBadge",
                        { attrs: { shape: "pill", color: "danger" } },
                        [_vm._v(_vm._s(_vm.$root.taskCount))]
                      )
                    : _vm._e()
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c("CHeaderNav", [
        _vm.$root.$data.me && _vm.$root.$data.portalInfo.code == "thso"
          ? _c("div", { staticClass: "mr-5" }, [
              _vm._v(_vm._s(_vm.$root.$data.me.EMail))
            ])
          : _vm._e(),
        _vm.$root.$data.me && _vm.$root.$data.portalInfo.code != "thso"
          ? _c(
              "div",
              {
                staticClass: "mr-5",
                attrs: { title: _vm.$root.$data.me.EMail }
              },
              [
                _vm._v(
                  _vm._s(_vm.$root.$data.me.FullName) +
                    " (" +
                    _vm._s(_vm.$root.$data.me.Username) +
                    ")"
                )
              ]
            )
          : _vm._e()
      ]),
      _c(
        "CSubheader",
        { staticClass: "px-3" },
        [_c("CBreadcrumbRouter", { staticClass: "border-0 mb-0" })],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }