<script>
export default {
  name: 'nav',
  computed: {
    sidebarItems() {
      let options = [
        {
          _name: 'CSidebarNavItem',
          name: 'Dashboard',
          to: '/',
          icon: 'cil-speedometer',
        },
        {
          _name: 'CSidebarNavTitle',
          _children: ['Content']
        },
        {
          _name: 'CSidebarNavItem',
          name: 'Albums',
          to: '/albums',
          icon: 'cil-album',
          show: this.$root.$data.me && this.$root.$data.me.access.VW1,
        },
        {
          _name: 'CSidebarNavItem',
          name: 'Tracks',
          to: '/tracks',
          icon: 'cil-audio-spectrum',
          show: this.$root.$data.me && this.$root.$data.me.access.VW1,
        },
        {
          _name: 'CSidebarNavItem',
          name: 'Track Builder',
          to: '/track-concept-create',
          icon: 'cil-note-add',
          show: this.$root.$data.me && this.$root.$data.me.access.RB2,
        },
        {
          _name: 'CSidebarNavItem',
          name: 'Release Builder',
          to: '/release-concept-create',
          icon: 'cil-playlist-add',
          show: this.$root.$data.me && this.$root.$data.me.access.RB1,
        },
        {
          _name: 'CSidebarNavItem',
          name: 'Projects',
          to: '/charly-projects',
          icon: 'cil-spreadsheet',
          show: this.$root.$data.me && (this.$root.$data.me.access.CP1 || this.$root.$data.me.access.CP2),
        },
        {
          _name: 'CSidebarNavItem',
          name: 'Tasks',
          to: '/charly-tasks',
          icon: 'cil-spreadsheet',
          show: this.$root.$data.me && (this.$root.$data.me.access.CP1),
        },
        {
          _name: 'CSidebarNavTitle',
          _children: ['Royalty Dashboard'],
          show: this.$root.$data.me && (this.$root.$data.me.access.ROY || this.$root.$data.me.access.TR1 || this.$root.$data.me.access.ST1),
        },
        {
          _name: 'CSidebarNavItem',
          name: 'Trends',
          to: '/trends',
          icon: 'cil-chart-line',
          show: this.$root.$data.me && this.$root.$data.me.access.TR1
        },
        {
          _name: 'CSidebarNavItem',
          name: this.$t('Analytics'),
          to: '/royalty-analytics',
          icon: 'cil-chart',
          show: this.$root.$data.me && this.$root.$data.me.access.ROY
        },
        {
          _name: 'CSidebarNavItem',
          name: this.$t('Accounting'),
          to: '/royalty-accounting',
          icon: 'cil-dollar',
          show: (this.$root.$data.portalInfo.useBalanceEntries) && this.$root.$data.me && this.$root.$data.me.access.ST1
        },
        {
          _name: 'CSidebarNavItem',
          name: this.$t('Accounting'),
          to: '/royalty-accounting-portal',
          icon: 'cil-dollar',
          show: (!this.$root.$data.portalInfo.useBalanceEntries) && this.$root.$data.me && this.$root.$data.me.access.ST1
        },
        {
          _name: 'CSidebarNavTitle',
          _children: ['Other']
        },
        {
          _name: 'CSidebarNavItem',
          name: 'Exports',
          to: '/exports',
          icon: 'cil-cloud-download',
          show: this.$root.$data.me && (this.$root.$data.me.access.ST1 || this.$root.$data.me.access.ROY),
        },
        {
          _name: 'CSidebarNavItem',
          name: 'Legacy Content Editor',
          href: 'https://content-editor.eu/',
          target: '_blank',
          icon: 'cil-settings',
          show: this.$root.$data.me && this.$root.$data.me.access.ACE,
        },
        {
          _name: 'CSidebarNavItem',
          name: 'Portals',
          to: '/portals',
          icon: 'cil-settings',
          show: this.$root.$data.portalInfo.code == 'thso' && this.$root.$data.me && this.$root.$data.me.IsSuperUser,
        },
        {
          _name: 'CSidebarNavItem',
          name: 'Page Snippets',
          to: '/pagesnippets',
          icon: 'cil-settings',
          show: this.$root.$data.portalInfo.code != 'thso' && this.$root.$data.me && this.$root.$data.me.PortalAdmin,
        },
        {
          _name: 'CSidebarNavItem',
          name: 'Sub Providers',
          to: '/subproviders',
          icon: 'cil-settings',
          show: this.$root.$data.portalInfo.code != 'thso' && this.$root.$data.me && this.$root.$data.me.PortalAdmin,
          
        },
        {
          _name: 'CSidebarNavItem',
          name: this.$root.$data.portalInfo.code == 'thso' ? 'Portal Users' : 'Users',
          to: '/portal-users',
          icon: 'cil-settings',
          show: this.$root.$data.me && (this.$root.$data.me.IsSuperUser || this.$root.$data.me.access.UserManagement === true),
        },
        {
          _name: 'CSidebarNavItem',
          name: 'Users',
          to: '/users',
          icon: 'cil-settings',
          show: this.$root.$data.portalInfo.code == 'thso' && this.$root.$data.me && this.$root.$data.me.IsSuperUser,
        },
        {
          _name: 'CSidebarNavItem',
          name: 'Documentation',
          to: '/documentation',
          icon: 'cil-book'
        },
        {
          _name: 'CSidebarNavItem',
          name: 'Support',
          to: '/support',
          icon: 'cil-chat-bubble'
        },
        {
          _name: 'CSidebarNavItem',
          name: 'Logout',
          to: '/logout',
          icon: 'cil-account-logout'
        },
      ]

      return [{
        _name: 'CSidebarNav',
        _children: options.filter(o => { return o.show != false })
      }]
    }
  }
}
</script>
