var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("CFooter", { attrs: { fixed: false } }, [
    _c("div"),
    _c("div", { staticClass: "mfs-auto" })
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }