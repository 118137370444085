var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "c-app",
      class: { "c-dark-theme": _vm.$store.state.darkMode }
    },
    [
      _vm.$root.$data.me != null && _vm.$root.$data.hideMenu == false
        ? _c("TheSidebar")
        : _vm._e(),
      _c(
        "CWrapper",
        [
          _vm.$root.$data.me != null && _vm.$root.$data.hideMenu == false
            ? _c("TheHeader")
            : _vm._e(),
          _c(
            "div",
            { staticClass: "c-body" },
            [
              _c(
                "main",
                { staticClass: "c-main" },
                [
                  _c(
                    "CContainer",
                    { attrs: { fluid: "" } },
                    [
                      _c(
                        "transition",
                        { attrs: { name: "fade" } },
                        [
                          !_vm.$root.$data.loggedIn ||
                          _vm.$root.$data.fieldDefs != null
                            ? _c("router-view")
                            : _vm._e(),
                          _vm.$root.$data.loggedIn &&
                          _vm.$root.$data.fieldDefs == null
                            ? _c("b-spinner", { attrs: { label: "Spinning" } })
                            : _vm._e()
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c("TheFooter")
            ],
            1
          )
        ],
        1
      ),
      _c("notifications", {
        staticClass: "ce-notification",
        attrs: { position: "top center" }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }