<template>
  <CHeader with-subheader>
    <CToggler
      in-header
      class="ml-3 d-lg-none"
      @click="$store.commit('toggleSidebarMobile')"
    />
    <CToggler
      in-header
      class="ml-3 d-md-down-none"
      @click="$store.commit('toggleSidebarDesktop')"
    />
    <CHeaderBrand
      class="mx-auto d-lg-none"
      src="@/assets/images/logo.png"
      width="190"
      height="46"
      alt="The Source Logo"
    />
    <CHeaderNav class="d-sm-down-none mr-auto">
      <CHeaderNavItem class="px-3">
        <CHeaderNavLink to="/">
          Dashboard
        </CHeaderNavLink>
      </CHeaderNavItem>
      <CHeaderNavItem class="px-3">
        <CHeaderNavLink to="/support">
          Known Issues / Support
        </CHeaderNavLink>
      </CHeaderNavItem>
      <CHeaderNavItem class="px-3">
        <CHeaderNavLink to="/tasks">
          <div style="padding-right: 40px;">Tasks</div>
          <CBadge v-if="$root.taskCount > 0" shape="pill" color="danger">{{ $root.taskCount }}</CBadge> 
        </CHeaderNavLink>
      </CHeaderNavItem>
    </CHeaderNav>
    <CHeaderNav>
      <div v-if="$root.$data.me && $root.$data.portalInfo.code == 'thso'" class="mr-5">{{ $root.$data.me.EMail }}</div>
      <div v-if="$root.$data.me && $root.$data.portalInfo.code != 'thso'" class="mr-5" :title="$root.$data.me.EMail">{{ $root.$data.me.FullName }} ({{ $root.$data.me.Username }})</div>
    </CHeaderNav>

    <CSubheader class="px-3">
      <CBreadcrumbRouter class="border-0 mb-0"/>
    </CSubheader>
  </CHeader>
</template>

<script>

export default {
  name: 'TheHeader'
}
</script>
