<template>
  <div class="c-app" :class="{ 'c-dark-theme': $store.state.darkMode }">
    <TheSidebar v-if="$root.$data.me != null && $root.$data.hideMenu == false" />
    <CWrapper>
      <TheHeader v-if="$root.$data.me != null && $root.$data.hideMenu == false" />
      <div class="c-body">
        <main class="c-main">
          <CContainer fluid>
            <transition name="fade">
              <router-view v-if="!$root.$data.loggedIn || $root.$data.fieldDefs != null"></router-view>
              <b-spinner v-if="$root.$data.loggedIn && $root.$data.fieldDefs == null" label="Spinning"></b-spinner>
            </transition>
          </CContainer>
        </main>
        <TheFooter/>
      </div>
    </CWrapper>

    <notifications class="ce-notification" position="top center" />
  </div>
</template>

<script>
//import TheAside from './TheAside'
import TheSidebar from './TheSidebar'
import TheHeader from './TheHeader'
import TheFooter from './TheFooter'

export default {
  name: 'TheContainer',
  components: {
//    TheAside,
    TheSidebar,
    TheHeader,
    TheFooter
  }
}
</script>

<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>
