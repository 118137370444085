var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "CSidebar",
    {
      attrs: { minimize: _vm.minimize, unfoldable: "", show: _vm.show },
      on: {
        "update:show": function(value) {
          return _vm.$store.commit("set", ["sidebarShow", value])
        }
      }
    },
    [
      _c("CSidebarBrand", { attrs: { to: "/" } }, [
        _c("img", {
          attrs: {
            src: _vm.$root.$data.portalInfo.logoMain,
            height: "46",
            alt: "Logo"
          }
        })
      ]),
      _c("CRenderFunction", {
        attrs: { flat: "", contentToRender: _vm.sidebarItems }
      }),
      _c("CSidebarMinimizer", {
        staticClass: "c-d-md-down-none",
        nativeOn: {
          click: function($event) {
            return _vm.$store.commit("toggle", "sidebarMinimize")
          }
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }